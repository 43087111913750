/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 -k -K --shadows -M .\\public\\models\\Boot.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { button, useControls } from "leva";
import * as THREE from "three";
export function Boot(props) {
  const { nodes, materials } = useGLTF("./models/Boot.glb");

  const values = useControls(
    "Boot",
    {
      Slipper: button(() => {
        props.setBootMode(false);
      }),
      outsole_color: { value: "#260E11", label: "Outsole Color", order: 0 },
      insole_color: {
        value: "#3C171C",
        label: "Insole Color",
        order: 1,
      },
      eyelets_color: { value: "#5C5959", label: "Eyelets Color", order: 2 },
      laces_color: { value: "#E76F0C", label: "Laces Color", order: 3 },
      lether_design_color: {
        value: "#651100",
        label: "Lether Design Color",
        order: 4,
      },
      fabric_design_color_1: {
        value: "#4C0B16",
        label: "Fabric design Color 1",
        order: 5,
      },
      fabric_design_color_2: {
        value: "#763F00",
        label: "Fabric design Color 2",
        order: 6,
      },
    },
    {}
  );

  return (
    <group {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="RealBoot_RubberWithPatternForBoot_0"
          castShadow
          receiveShadow
          geometry={nodes.RealBoot_RubberWithPatternForBoot_0.geometry}
          material={materials.RubberWithPatternForBoot}
          material-color={values.insole_color}
          position={[-17.826, 2.895, -5.907]}
          rotation={[-Math.PI / 2, 0.061, 0]}
          scale={100}
        />
        <mesh
          name="RealBoot_RubberForBoot_0"
          castShadow
          receiveShadow
          geometry={nodes.RealBoot_RubberForBoot_0.geometry}
          material={materials.RubberForBoot}
          material-color={values.outsole_color}
          position={[-17.826, 2.895, -5.907]}
          rotation={[-Math.PI / 2, 0.061, 0]}
          scale={100}
        />
        <mesh
          name="RealBoot_LeatherForBoot_0"
          castShadow
          receiveShadow
          geometry={nodes.RealBoot_LeatherForBoot_0.geometry}
          material={materials.LeatherForBoot}
          material-color={values.lether_design_color}
          position={[-17.826, 2.895, -5.907]}
          rotation={[-Math.PI / 2, 0.061, 0]}
          scale={100}
        />
        <mesh
          name="RealBoot_FabricForBoot_0"
          castShadow
          receiveShadow
          geometry={nodes.RealBoot_FabricForBoot_0.geometry}
          material={materials.FabricForBoot}
          material-color={values.fabric_design_color_1}
          position={[-17.826, 2.895, -5.907]}
          rotation={[-Math.PI / 2, 0.061, 0]}
          scale={100}
        >
          {/* <meshStandardMaterial
            color={values.fabric_design_color_1}
            attach="material"
            normalMap={normalMap}
            normalScale={0.2}
          /> */}
        </mesh>
        <mesh
          name="RealBoot_FabricForBoot001_0"
          castShadow
          receiveShadow
          geometry={nodes.RealBoot_FabricForBoot001_0.geometry}
          material={materials["FabricForBoot.001"]}
          material-color={values.fabric_design_color_2}
          position={[-17.826, 2.895, -5.907]}
          rotation={[-Math.PI / 2, 0.061, 0]}
          scale={100}
        />
        <mesh
          name="Circle038_MetalForBoot_0"
          castShadow
          receiveShadow
          geometry={nodes.Circle038_MetalForBoot_0.geometry}
          material={materials.MetalForBoot}
          material-color={values.eyelets_color}
          position={[2.361, 5.573, 5.629]}
          rotation={[-1.35, 0.691, 0.112]}
          scale={0.437}
        />
        <mesh
          name="RealBootLine_FabricForBoot002_0"
          castShadow
          receiveShadow
          geometry={nodes.RealBootLine_FabricForBoot002_0.geometry}
          material={materials["FabricForBoot.002"]}
          material-color={values.laces_color}
          position={[-17.826, 2.895, -5.907]}
          rotation={[-Math.PI / 2, 0.061, 0]}
          scale={100}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/Boot.glb");
