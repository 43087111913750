/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 -k -K --shadows -M .\\public\\models\\Slippers.glb 
*/

import React, { useRef } from "react";
import { useGLTF, useAnimations, useTexture } from "@react-three/drei";
import { button, useControls } from "leva";

const textures = {
  cartoon_texture: "./textures/texture_1.jpg",
  teclox_texture: "./textures/texture_2.png",
  beach_texture: "./textures/beach.jpg",
};

const patterns = {
  none: "./textures/black.jpg",
  pattern_1: "./textures/pattern_1.jpg",
};

export function Slippers(props) {
  const values = useControls(
    "Slipper",
    {
      footbed_color: { value: "#ff9621", label: "Main Color", order: 1 },
      strap_color: { value: "#0000ff", label: "Strap Color", order: 2 },
      logo_color: { value: "#fff300", label: "Log Color", order: 3 },
      add_texture: { value: false, label: "Try Textures", order: 4 },
      texture: {
        value: textures["cartoon_texture"],
        render: (get) => get("Slipper.add_texture"),
        options: textures,
        order: 5,
      },
      pattern: { value: patterns["pattern_1"], options: patterns, order: 6 },
      Boot: button(() => {
        props.setBootMode(true);
      }),
    },
    {}
  );
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("./models/Slippers.glb");
  const texture = useTexture(values?.texture);
  const normalMap = useTexture(values?.pattern);
  const { actions } = useAnimations(animations, group);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="Slipper"
          castShadow
          receiveShadow
          geometry={nodes.Slipper.geometry}
          material={materials.Main_Color}
          material-color={values.footbed_color}
          position={[-0.826, -0.015, -1.641]}
          scale={0.275}
        >
          {values.add_texture && (
            <meshStandardMaterial map={texture} normalMap={normalMap} />
          )}
        </mesh>
        <mesh
          name="Strip"
          castShadow
          receiveShadow
          geometry={nodes.Strip.geometry}
          material={materials.Strap}
          material-color={values.strap_color}
          position={[-0.82, 0.198, -1.34]}
          scale={0.101}
        />
        <mesh
          name="Logo"
          castShadow
          receiveShadow
          geometry={nodes.Logo.geometry}
          material={materials.Logo}
          material-color={values.logo_color}
          position={[-1.578, 0.389, -0.395]}
          rotation={[0.2, 0.957, -0.168]}
          scale={0.242}
        />
        <mesh
          name="Slipper001"
          castShadow
          receiveShadow
          geometry={nodes.Slipper001.geometry}
          material={materials.Main_Color}
          material-color={values.footbed_color}
          position={[0.791, -0.015, -1.641]}
          rotation={[-Math.PI, 0, 0]}
          scale={-0.275}
        >
          {values.add_texture && (
            <meshStandardMaterial map={texture} normalMap={normalMap} />
          )}
        </mesh>
        <mesh
          name="Strip001"
          castShadow
          receiveShadow
          geometry={nodes.Strip001.geometry}
          material={materials.Strap}
          material-color={values.strap_color}
          position={[0.785, 0.198, -1.34]}
          rotation={[-Math.PI, 0, 0]}
          scale={-0.101}
        />
        <mesh
          name="Logo001"
          castShadow
          receiveShadow
          geometry={nodes.Logo001.geometry}
          material={materials.Logo}
          material-color={values.logo_color}
          position={[1.663, 0.389, -0.395]}
          rotation={[2.942, 0.987, -2.964]}
          scale={0.242}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/Slippers.glb");
