import {
  Environment,
  OrbitControls,
  AccumulativeShadows,
  Center,
  RandomizedLight,
  Html,
  Text,
} from "@react-three/drei";
import "./App.css";
import { Canvas } from "@react-three/fiber";
import { Slippers } from "./components/Slippers";
import { Boot } from "./components/Boot";
import { Suspense, useState } from "react";
import { Vector3 } from "three";

function App() {
  const [bootMode, setBootMode] = useState(true);

  return (
    <>
      <div className="ui">
        <img src="./Logo_color.png" width={250} className="logo" alt="abc" />
        <p className="copyright">© 2023 Supun Sethsara </p>
      </div>
      <Canvas
        gl={{ antialias: false, preserveDrawingBuffer: true }}
        shadows
        camera={{ position: [5, 0, 9], fov: 35 }}
      >
        <group position={[0, -0.75, 0]}>
          <Center top>
            {/* <Slippers
            footbed_color={values.footbed_color}
            logo_color={values.logo_color}
            strap_color={values.strap_color}
            add_texture={values.add_texture}
            texture={values.texture}
            pattern={values.pattern}
          /> */}
            <Suspense fallback={<Loading />}>
              {!bootMode && (
                <Slippers
                  setBootMode={(value) => {
                    setBootMode(value);
                  }}
                />
              )}
              {bootMode && (
                <Boot
                  setBootMode={(value) => {
                    setBootMode(value);
                  }}
                  scale={0.17}
                />
              )}
            </Suspense>
          </Center>
          <AccumulativeShadows scale={15}>
            <RandomizedLight position={[2, 5, 5]} />
          </AccumulativeShadows>
        </group>
        <OrbitControls
          makeDefault
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 2}
        />
        <Environment files={"thatch_chapel_1k.hdr"} background blur={1} />
      </Canvas>
    </>
  );
}

function Loading() {
  return <Text>Loading...</Text>;
}

export default App;
